import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
		<path d="M2280 3990 c-62 -5 -99 -14 -135 -33 l-51 -25 -89 -276 -90 -276 -90
0 c-333 -1 -601 -199 -671 -495 -23 -100 -15 -247 20 -338 46 -123 166 -275
269 -341 15 -10 27 -23 27 -28 0 -23 -129 -224 -197 -308 -40 -49 -69 -91 -65
-94 22 -13 172 -36 239 -36 156 1 258 45 379 165 45 45 88 95 97 111 16 30 17
31 134 43 273 29 487 88 732 203 57 26 106 48 108 48 3 0 49 -46 102 -101 87
-93 149 -166 268 -322 124 -161 338 -205 481 -99 31 22 33 28 27 61 -22 122
-145 361 -271 528 -43 57 -75 112 -91 158 -14 38 -32 83 -40 98 -14 28 -13 29
72 130 150 177 276 380 345 557 81 205 81 208 2 89 -112 -169 -261 -335 -410
-460 l-95 -79 -57 148 c-165 425 -339 750 -454 848 -120 103 -272 141 -496
124z m394 -709 c42 -112 76 -205 76 -208 0 -3 -15 5 -32 17 -18 11 -96 53
-173 91 -76 38 -141 71 -142 73 -3 2 99 296 124 356 8 22 58 -90 147 -329z
m-914 -378 c-1 -25 -118 -347 -127 -350 -7 -3 -24 14 -39 37 -72 109 -23 248
108 305 37 16 58 19 58 8z m684 -225 c139 -92 199 -139 189 -148 -14 -15 -218
-68 -336 -89 -165 -29 -197 -31 -197 -16 0 7 16 55 36 106 19 52 49 130 65
173 17 44 33 84 35 88 7 10 86 -34 208 -114z"/>
<path d="M769 1473 c-34 -89 -69 -186 -69 -194 0 -5 16 -9 35 -9 24 0 35 5 35
15 0 11 12 15 44 15 30 0 46 -5 50 -15 3 -9 19 -15 41 -15 19 0 35 2 35 5 0 4
-60 171 -75 208 -5 12 -18 17 -46 17 -34 0 -41 -4 -50 -27z m71 -110 c0 -7 -9
-13 -20 -13 -21 0 -25 16 -13 60 l7 25 12 -30 c7 -17 13 -36 14 -42z"/>
<path d="M1020 1488 c-73 -38 -75 -174 -3 -207 31 -14 95 -14 126 0 23 10 47
46 47 69 0 19 -65 11 -78 -10 -14 -22 -42 -26 -60 -8 -17 17 -15 93 3 108 20
17 48 9 60 -18 7 -16 18 -22 42 -22 41 0 44 26 10 66 -28 32 -106 43 -147 22z"/>
<path d="M1217 1493 c-4 -3 -7 -17 -7 -30 0 -20 5 -23 40 -23 l40 0 0 -85 0
-85 35 0 35 0 0 85 0 85 38 0 c37 0 38 1 34 30 l-4 30 -102 0 c-57 0 -106 -3
-109 -7z"/>
<path d="M1470 1385 l0 -115 35 0 35 0 0 115 0 115 -35 0 -35 0 0 -115z"/>
<path d="M1584 1483 c3 -10 21 -61 40 -113 l34 -95 43 0 44 0 37 105 c20 58
37 108 37 113 1 4 -15 7 -34 7 -34 0 -34 0 -58 -77 l-24 -78 -25 75 c-24 74
-25 75 -62 78 -33 3 -37 1 -32 -15z"/>
<path d="M1859 1413 c-1 -49 -2 -99 -3 -113 -1 -25 0 -25 97 -28 l97 -3 0 26
c0 24 -2 25 -60 25 -53 0 -60 2 -60 20 0 17 7 20 50 20 47 0 50 2 50 25 0 23
-3 25 -50 25 -38 0 -50 4 -50 15 0 12 13 15 60 15 l60 0 0 30 0 30 -95 0 -95
0 -1 -87z"/>
<path d="M2200 1386 l0 -116 65 0 c93 0 131 18 146 70 16 54 3 106 -34 134
-21 15 -47 22 -103 24 l-74 4 0 -116z m140 33 c15 -27 3 -77 -22 -90 -35 -19
-48 -4 -48 56 0 54 0 55 30 55 19 0 33 -7 40 -21z"/>
<path d="M2485 1398 c-21 -57 -40 -109 -42 -115 -4 -9 7 -13 36 -13 30 0 41 4
41 15 0 11 12 15 45 15 33 0 45 -4 45 -15 0 -11 11 -15 39 -15 l40 0 -31 83
c-17 45 -35 97 -41 115 -9 29 -14 32 -52 32 l-42 0 -38 -102z m94 -25 c1 -17
-5 -23 -19 -23 -23 0 -24 5 -8 50 10 30 12 32 19 14 4 -10 8 -29 8 -41z"/>
<path d="M2700 1470 c0 -28 2 -30 40 -30 l40 0 0 -85 0 -85 35 0 35 0 0 85 0
85 35 0 c32 0 35 2 35 30 l0 30 -110 0 -110 0 0 -30z"/>
<path d="M3015 1483 c-15 -37 -75 -204 -75 -208 0 -13 69 -3 74 10 3 10 19 15
46 15 27 0 43 -5 46 -15 8 -19 74 -21 74 -2 -1 6 -18 57 -39 112 l-38 100 -40
3 c-31 2 -42 -1 -48 -15z m59 -85 c10 -38 7 -48 -14 -48 -11 0 -20 6 -20 13 0
23 12 57 20 57 4 0 10 -10 14 -22z"/>
<path d="M3219 1413 c-1 -49 -2 -99 -3 -113 -1 -22 4 -25 37 -28 36 -3 37 -2
37 31 0 33 2 35 41 40 54 7 79 31 79 76 0 63 -28 81 -124 81 l-66 0 -1 -87z
m121 7 c0 -15 -7 -20 -25 -20 -18 0 -25 5 -25 20 0 15 7 20 25 20 18 0 25 -5
25 -20z"/>
<path d="M3560 1385 l0 -115 95 0 95 0 0 30 c0 28 -1 29 -57 32 l-58 3 -3 83
-3 82 -34 0 -35 0 0 -115z"/>
<path d="M3786 1472 c-4 -15 -4 -57 -1 -93 7 -83 32 -109 105 -109 98 0 120
28 120 150 l0 80 -35 0 -35 0 0 -79 c0 -87 -12 -108 -54 -97 -24 6 -25 10 -28
89 l-3 82 -32 3 c-27 3 -32 0 -37 -26z"/>
<path d="M4115 1491 c-76 -31 -86 -173 -16 -211 26 -13 97 -13 124 1 16 9 22
9 25 0 2 -6 12 -11 23 -11 17 0 19 8 19 65 l0 65 -60 0 c-57 0 -60 -1 -60 -25
0 -20 5 -25 26 -25 22 0 25 -2 14 -15 -35 -42 -98 6 -85 65 9 42 45 59 79 36
24 -15 86 -23 86 -10 0 17 -24 46 -50 59 -31 16 -92 19 -125 6z"/>
<path d="M1887 1002 c-26 -28 -22 -78 8 -98 26 -17 56 -16 62 3 2 6 -8 13 -23
15 -53 6 -58 68 -5 76 11 2 21 8 21 13 0 16 -46 10 -63 -9z"/>
<path d="M2047 1002 c-23 -25 -21 -68 3 -92 58 -58 142 20 94 88 -19 28 -74
30 -97 4z m69 -10 c22 -15 24 -42 5 -61 -24 -24 -57 -17 -65 12 -10 41 27 72
60 49z"/>
<path d="M2240 957 c0 -38 4 -67 10 -67 6 0 10 16 10 37 0 34 1 35 18 20 17
-15 19 -15 35 0 16 16 17 15 17 -21 0 -26 4 -36 13 -34 15 5 23 128 8 128 -5
0 -19 -12 -31 -27 l-20 -26 -30 28 -30 29 0 -67z"/>
<path d="M2437 1014 c-4 -4 -7 -34 -7 -66 0 -45 3 -58 15 -58 9 0 15 9 15 24
0 18 6 26 21 28 30 4 47 33 33 56 -11 17 -64 29 -77 16z m56 -29 c1 -5 -6 -11
-15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z"/>
<path d="M2608 954 c-27 -64 -25 -75 12 -52 16 10 24 10 41 0 12 -7 23 -11 25
-9 5 5 -33 108 -44 119 -4 4 -19 -22 -34 -58z m42 -15 c0 -5 -4 -9 -10 -9 -5
0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z"/>
<path d="M2760 957 c0 -38 4 -67 10 -67 6 0 11 8 12 18 0 9 2 22 3 27 2 6 3
13 4 17 0 4 17 -9 36 -30 l34 -37 1 68 c0 38 -4 67 -10 67 -9 0 -15 -29 -11
-52 2 -16 -14 -7 -46 24 l-33 32 0 -67z"/>
<path d="M2949 986 c12 -18 21 -48 21 -66 0 -21 4 -30 13 -27 6 2 12 17 12 33
1 17 11 44 24 62 17 25 19 32 7 32 -8 0 -22 -10 -30 -21 l-14 -21 -14 21 c-7
11 -19 21 -26 21 -10 0 -8 -9 7 -34z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
